import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Spacer } from 'ascential-horizon'
import getThemeConfig from '../providers/config/themeConfig'
import { getBasicHero } from '../services/hero'
import Layout from '../Layout'
import Breadcrumbs from '../components/Breadcrumbs'
import Carousel from '../components/Carousel'
import Hero from '../components/Hero'
import { useIdentity } from '../providers/identityContext'

const TemplateTaxonomyPage = ({ data, pageContext, location }) => {
  const { loggedIn, user } = useIdentity()

  const {
    footer: { type },
    header: { variation },
    showBreadcrumbs,
    videoPlatform: { pagePrefix },
  } = getThemeConfig()

  const {
    footerNav,
    header,
    headerAuth,
    home: { footer },
  } = data

  const {
    breadcrumb: { crumbs },
    cards,
    pageTitle,
    taxonomy: { path, description },
  } = pageContext

  const getHeaderProps = () => {
    if (user?.loading) {
      return <></>
    }
    const headerProps =
      variation === 'nav'
        ? {
            ...(loggedIn && headerAuth ? headerAuth : header),
          }
        : {
            ...data.home.header,
          }
    return headerProps
  }

  const footerProps = type === 'menu' ? footerNav : footer

  const layoutProps = {
    header: getHeaderProps(),
    footer: footerProps,
    pageContext,
    location,
  }

  const carouselProps =
    cards && cards.length > 0
      ? {
          banner: cards[0].categoryBanner,
          cards,
          ctaUrl: path,
          display: 'grid',
          showCta: false,
          description,
        }
      : null

  const hero = getBasicHero()
  const { heroImage, mobileImage } = hero
  const heroProps = {
    variant: 'standard',
    mobileImage: mobileImage || null,
    title: pageTitle,
    image: heroImage,
    ctaLabel: null,
  }

  // Removing second Crumb as the Taxonomy listing pages do not exist yet
  // TODO refactor once Taxonomy listing pages become a thing
  crumbs.splice(pagePrefix ? 2 : 1, 1)

  const breadcrumbProps = {
    crumbs,
    crumbLabel: pageTitle,
    location,
  }

  return (
    <Layout {...layoutProps}>
      {showBreadcrumbs && <Breadcrumbs {...breadcrumbProps} />}
      <Hero {...heroProps} />
      <Spacer>{carouselProps && <Carousel {...carouselProps} />}</Spacer>
    </Layout>
  )
}

TemplateTaxonomyPage.propTypes = {
  pageContext: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    hostname: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export const templateTaxonomyPageQuery = graphql`
  query($rootId: String!) {
    header: contentfulNav(instanceName: { eq: "Main Menu" }) {
      ...navQuery
    }
    headerAuth: contentfulNav(instanceName: { eq: "Main Menu Auth" }) {
      ...navQuery
    }
    footerNav: contentfulFooter(instanceName: { eq: "Footer" }) {
      ...footerQuery
    }
    home: contentfulTemplateHomePage(id: { eq: $rootId }) {
      ...header
      footer {
        ...footerQuery
      }
    }
  }
`

export default TemplateTaxonomyPage
